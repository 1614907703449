import React, {useCallback, useEffect, useState} from "react";
import {apiGet} from "../utils";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {IconButton, Typography} from "@material-ui/core";
import {RotateLeft} from "@material-ui/icons";
import {useMediaQuery} from "@mui/material";
import Button from "@material-ui/core/Button";
import {ActivityLogGrid} from "../components/ActivityLogGrid";

export const ActivityContainer = () => {
    const [events, setEvents] = useState([]);
    const [pagesLoaded, setPagesLoaded] = useState(0);
    const [hideMoreButton, setHideMoreButton] = useState(false);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width: 715px)');

    const fetchRecentActivity = () => {
        setLoading(true);
        const pageParam = (pagesLoaded > 0) ? `?page=${pagesLoaded + 1}` : '';
        let numEventsAlreadyLoaded = events.length;
        apiGet(`activity/recent${pageParam}`, (data) => {
            const summaries = [];
            if (data.data && data.data.length > 0) {
                for (const event of data.data) {
                    let managerName = ['ADD', 'DROP', 'TRADE'].includes(event.type) ? event.manager_name : 'SYSTEM'
                    let summaryStr;
                    switch (event.type) {
                        case 'ADD':
                            summaryStr = `${event.manager_name} added ${event.first_name} ${event.last_name} (${event.pos} - ${event.team}) ${event.description}`;
                            break;
                        case 'DROP':
                            summaryStr = `${event.manager_name} dropped ${event.first_name} ${event.last_name} (${event.pos} - ${event.team})`;
                            break;
                        case 'TRADE':
                            summaryStr = `${event.manager_name} traded ${event.first_name} ${event.last_name} (${event.pos} - ${event.team}) ${event.description}`;
                            break;
                        case 'AUCTION_CLOSE':
                            summaryStr = `Auction closed for season ${event.season_year} week ${event.week_num}`;
                            break;
                        case 'SEASON':
                        case 'SYNC':
                        case 'ADMIN':
                            summaryStr = `${event.description}`;
                            break;
                        default:
                            break;
                    }
                    summaries.push({
                        id: ++numEventsAlreadyLoaded,
                        ts: event.ts,
                        manager: managerName,
                        summary: summaryStr
                    });
                }
            } else {
                setHideMoreButton(true);
            }
            setEvents([...events, ...summaries]);
            setPagesLoaded(pagesLoaded + 1);
            setLoading(false);
        });
    };

    const refresh = useCallback(() => {
        setPagesLoaded(0);
        setHideMoreButton(false);
        fetchRecentActivity();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const justify = isMobile ? 'flex-start' : 'center';
    return (
        <div>
            <Grid container justifyContent={justify}>
                <div style={{marginTop: 30}}>
                    {loading && <LinearProgress color="secondary"/>}
                    <div style={{float: "right"}}>
                        <IconButton color="inherit" style={{height: 25}} onClick={refresh}>
                            <RotateLeft/>
                        </IconButton>
                    </div>
                    <Typography variant="h6" color="inherit" display="inline" style={{marginLeft: 10}}>
                        Recent Activity:
                    </Typography>
                    <ActivityLogGrid rows={events}/>
                    <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                            onClick={fetchRecentActivity} disabled={hideMoreButton}>More</Button>
                </div>
            </Grid>
            <br/>
            <br/>
        </div>
    );
}