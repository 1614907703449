import React from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {Button, Checkbox, Typography} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {config} from "../config";

let onDropClick, onCheckboxClick;
const renderSalaryAmount = (params) => {
    return params.row.salary ? params.row.salary : '';  //TODO: if pre-draft, display RFA instead of blank
};
const renderDropPenalty = (params) => {
    return params.row.penalty > 0 ? params.row.penalty : ' ';
};
const renderProjectedDropPenalty = (params) => {
    return params.row.penalty_next_year > 0 ? params.row.penalty_next_year : ' ';
};
const renderDropButton = (params) => {
    if (params.row.dropped_year) {
        return (<div/>);
    } else {
        const onClick = async () => {
            onDropClick(params.row);
        }
        return (
            <Button color="primary" onClick={onClick} style={{padding: 0}}>Drop</Button>
        )
    }
};

const renderTradeCheckbox = (params) => {
    const handleChange = async (event) => {
        onCheckboxClick(params.row, event.target.checked);
    }
    return (
        <Checkbox onChange={handleChange} style={{padding: 0}}/>
    )
};

const rosterColumns = [
    {
        sortable: false, disableColumnMenu: true, width: 65,
        headerName: 'Num', field: 'row_number', type: 'number', headerAlign: 'right'
    },
    {
        sortable: false, disableColumnMenu: true, width: 130,
        headerName: 'First Name', field: 'first_name'
    },
    {
        sortable: false, disableColumnMenu: true, width: 130,
        headerName: 'Last Name', field: 'last_name'
    },
    {
        sortable: false, disableColumnMenu: true, width: 60,
        headerName: 'Pos', field: 'pos', headerAlign: 'center', align: 'center'
    },
    {
        sortable: false, disableColumnMenu: true, width: 70,
        headerName: 'Team', field: 'team', headerAlign: 'center', align: 'center'
    },
    {
        sortable: false, disableColumnMenu: true, width: 70,
        headerName: 'Year', field: 'signed_year', headerAlign: 'center', align: 'center'
    }
];

const normalMoneyColumns = [
    {
        sortable: false, disableColumnMenu: true, width: 75,
        headerName: 'Salary', field: 'salary', type: 'number', headerAlign: 'right',
        renderCell: renderSalaryAmount
    },
    {
        sortable: false, disableColumnMenu: true, width: 80,
        headerName: 'Penalty', field: 'penalty', type: 'number', headerAlign: 'right',
        renderCell: renderDropPenalty
    }
]

const projectedMoneyColumns = [
    {
        sortable: false, disableColumnMenu: true, width: 110,
        headerName: 'New Salary', field: 'salary', type: 'number', headerAlign: 'right',
        renderCell: renderSalaryAmount
    },
    {
        sortable: false, disableColumnMenu: true, width: 90,
        headerName: 'Drop Pre', field: 'penalty', type: 'number', headerAlign: 'right',
        renderCell: renderDropPenalty
    },
    {
        sortable: false, disableColumnMenu: true, width: 100,
        headerName: 'Drop Post', field: 'penalty_next_year', type: 'number', headerAlign: 'right',
        renderCell: renderProjectedDropPenalty
    }
]

const actionColumns = [
    {
        sortable: false, disableColumnMenu: true, width: 85,
        headerName: ' ', field: 'drop', align: 'center',
        renderCell: renderDropButton
    }
]

const tradeColumns = [
    {
        sortable: false, disableColumnMenu: true, width: 60,
        headerName: ' ', field: 'trade', align: 'center',
        renderCell: renderTradeCheckbox
    }
]

const determineRowClass = (params) => {
    let classes;
    if ('was_active' in params.row) {
        classes = `${!params.row.was_active ? 'penalty' : params.row.display_order % 2 === 1 ? 'table-row-odd' : 'table-row-even'}`;
    } else {
        classes = `${!params.row.is_owned ? 'penalty' : params.row.display_order % 2 === 1 ? 'table-row-odd' : 'table-row-even'}`;
        classes += params.row.transaction_key ? '' : ' needs-yahoo-sync';
        classes += params.row.proposed ? ' proposed-trade' : '';
        classes += params.row.salary ? '' : ' is-rfa';
    }
    return classes;
};

const determineOverRosterSize = (rows) => {
    const numOwned = rows.reduce((total, curr) => curr.is_owned ? total + 1 : total, 0);
    return numOwned > config.maxRosterSize;
};

const determineOverBudget = (spent) => {
    return spent > config.salaryCap;
};

const sortRosterRows = (rows) => {
    rows.sort((a, b) => {
        if (!a.is_owned && b.is_owned) return 1;
        if (a.is_owned && !b.is_owned) return -1;
        if (!a.was_active && b.was_active) return 1;
        if (a.was_active && !b.was_active) return -1;
        if ((a.is_owned && b.is_owned) || (a.was_active && b.was_active)) {
            if (a.display_order > b.display_order) return 1;
            if (a.display_order < b.display_order) return -1;
            if (a.salary < b.salary) return 1;
            if (a.salary > b.salary) return -1;
        } else {
            if (a.signed_year > b.signed_year) return 1;
            if (a.signed_year < b.signed_year) return -1;
            if (a.penalty < b.penalty) return 1;
            if (a.penalty > b.penalty) return -1;
        }
        if (a.last_name > b.last_name) return 1;
        if (a.last_name < b.last_name) return -1;
        if (a.first_name > b.first_name) return 1;
        if (a.first_name < b.first_name) return -1;
        return 0;
    });
    renumberRosterRows(rows);
};

const renumberRosterRows = (rows) => {
    let owned = 0;
    for (let i = 0; i < rows.length; i++) {
        if (rows[i].is_owned || rows[i].was_active) {
            rows[i].row_number = i + 1;
            owned++;
        } else {
            rows[i].row_number = i + 1 - owned;
        }
    }
};

export const RosterGrid = ({rows, spent, loading, showActions, onDropButtonClick, showTrading, onTradeBoxClick, showProjPenalties}) => {
    const rowsCopy = JSON.parse(JSON.stringify(rows));
    sortRosterRows(rowsCopy);
    let displayCols = [];
    displayCols.push(...rosterColumns);
    if (showProjPenalties) {
        displayCols.push(...projectedMoneyColumns);
    } else {
        displayCols.push(...normalMoneyColumns);
    }
    if (showActions) displayCols.push(...actionColumns);
    if (showTrading) displayCols.push(...tradeColumns);
    onDropClick = onDropButtonClick;
    onCheckboxClick = onTradeBoxClick
    return (
        <div style={{width: 685 + (showProjPenalties ? 145 : 0) + (showActions ? 85 : 0) + (showTrading ? 60 : 0), marginLeft: 10, marginRight: 10}}>
            {loading && <LinearProgress color="secondary"/>}
            <DataGrid columns={displayCols} rows={rowsCopy} hideFooterPagination={true} columnHeaderHeight={30} rowHeight={23}
                      autoHeight getRowClassName={(params) => (determineRowClass(params))}
                      disableRowSelectionOnClick disableMultipleRowSelection disableColumnResize
                      slots={{footer: RosterGridFooter}} slotProps={{
                footer: {
                    spent: spent,
                    isOverRosterSize: determineOverRosterSize(rowsCopy),
                    isOverBudget: determineOverBudget(spent)
                }
            }}/>
        </div>
    );
}

export const RosterGridFooter = ({spent, isOverRosterSize, isOverBudget}) => {
    return (
        <div>
            <div style={{padding: 20, float: 'left', textAlign: 'left'}}>
                {isOverRosterSize && <Typography variant="h5" color="error">TOO MANY PLAYERS</Typography>}
                {isOverBudget && <Typography variant="h5" color="error">OVER BUDGET</Typography>}
            </div>
            {spent &&
                <div style={{padding: 20, float: 'right', textAlign: 'right'}}>
                    <Typography variant="h6" color="inherit">Used Bank: {spent}</Typography>
                    <Typography variant="h6" color="inherit">Available: {config.salaryCap - spent}</Typography>
                </div>
            }
        </div>
    );
}