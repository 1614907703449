import React, {useCallback, useEffect, useState} from "react";
import {apiGet, apiPost} from "../utils";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {IconButton} from "@material-ui/core";
import {RotateLeft} from "@material-ui/icons";
import {config} from "../config";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useMediaQuery} from "@mui/material";

export const AdminContainer = () => {
    const [dbMeta, setDbMeta] = useState({});
    const [dbVersions, setDbVersions] = useState({});
    const [mailTo, setMailTo] = useState('');
    const [yahooMeta, setYahooMeta] = useState('');
    const [currentSeason, setCurrentSeason] = useState({});
    const [currentLeague, setCurrentLeague] = useState({});
    const [currentAuction, setCurrentAuction] = useState({});
    const [anchorElWeek, setAnchorElWeek] = useState(null);
    const [selectedWeekIndex, setSelectedWeekIndex] = useState(null);
    const [consoleText, setConsoleText] = useState('');
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width: 620px)');

    const fetchDbHost = () => {
        apiGet(`admin/database`, (data) => {
            setDbMeta(data.data ? {host: data.data.host, name: data.data.name} : {});
        });
    };

    const fetchDbVersions = () => {
        apiGet(`admin/database/meta`, (data) => {
            setDbVersions(data.data ? {migration: data.data.migration.version, data: data.data.data.description} : {});
        });
    };

    const fetchEmailTo = () => {
        apiGet(`admin/mailto`, (data) => {
            setMailTo(data.data ? data.data.emailTo : '');
        });
    };

    const fetchCurrentSeason = () => {
        apiGet(`season/current`, (data) => {
            setCurrentSeason(data.data ? data.data : {});
        });
    };

    const fetchCurrentLeague = () => {
        apiGet(`league/current`, (data) => {
            setCurrentLeague(data.data ? data.data : {});
        });
    };

    const fetchCurrentAuction = () => {
        apiGet(`auction/current`, (data) => {
            setSelectedWeekIndex(config.weekNums.indexOf(data.data.week_num));
            setCurrentAuction(data.data ? data.data : {});
        });
    };

    const fetchYahooMetadata = () => {
        setLoading(true);
        apiGet(`yahoo/meta`, (data) => {
            setYahooMeta(data.data ? data.data.fantasy_content.game[0].game_key : '');
            setLoading(false);
        });
    };

    const refresh = useCallback(() => {
        fetchDbHost();
        fetchDbVersions();
        fetchEmailTo();
        fetchCurrentSeason();
        fetchCurrentLeague();
        fetchCurrentAuction();
        setConsoleText('');
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleClickWeek = (event) => {
        setAnchorElWeek(event.currentTarget);
    };

    const handleWeekMenuItemClick = (event, index) => {
        setSelectedWeekIndex(index);
        setAnchorElWeek(null);
    };

    const handleClose = () => {
        setAnchorElWeek(null);
    };

    const printMessage = (message) => {
        console.log(message);
        setConsoleText(`${consoleText}${"-".repeat(50)}\n${message}\n${"-".repeat(50)}\n`);
    };

    const submitFetchNFLScheduleData = () => {
        setLoading(true);
        const gamesPostBody = {
            year: currentSeason.year
        }
        apiPost(`admin/games/fetch/schedule`, gamesPostBody, (data) => {
            printMessage(`Populated game schedule data: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitFetchNFLResultsData = () => {
        setLoading(true);
        const gamesPostBody = {
            year: currentSeason.year,
            week: config.weekNums[selectedWeekIndex]
        }
        apiPost(`admin/games/fetch/results`, gamesPostBody, (data) => {
            printMessage(`Populated game results data: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitYahooSyncPlayers = () => {
        setLoading(true);
        apiPost(`yahoo/sync/players`, {}, (data) => {
            printMessage(`Sync player data: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitYahooSyncTransactions = () => {
        setLoading(true);
        apiPost(`yahoo/sync/transactions`, {}, (data) => {
            printMessage(`Sync transaction data: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitMatchTransactions = () => {
        setLoading(true);
        apiPost(`contract/match`, {}, (data) => {
            printMessage(`Match contracts and transactions: ${JSON.stringify(data, null, 2)}`);
            setLoading(false);
        });
    };

    const submitAdvanceToPreDraft = () => {
        setLoading(true);
        apiPost(`admin/season/advance/predraft`, {}, (data) => {
            printMessage(`Advanced to pre-draft of season ${data.data.advance_to_predraft}`);
            setLoading(false);
        });
    };

    const submitAdvanceSeason = () => {
        setLoading(true);
        apiPost(`admin/season/advance`, {}, (data) => {
            printMessage(`Advanced contracts to ${data.data.advance_contracts}`);
            setLoading(false);
        });
    };

    const submitAdvanceToDraft = () => {
        setLoading(true);
        apiPost(`admin/season/advance/draft`, {}, (data) => {
            printMessage(`Advanced to draft of season ${data.data.advance_to_draft}`);
            setLoading(false);
        });
    };

    const justify = isMobile ? 'flex-start' : 'center';
    return (
        <Grid container justifyContent={justify}>
            <div style={{marginTop: 30}}>
                {loading && <LinearProgress color="secondary"/>}
                <div style={{float: "right"}}>
                    <IconButton color="inherit" style={{height: 25}} onClick={refresh}>
                        <RotateLeft/>
                    </IconButton>
                </div>
                Metadata:
                <br/>
                <pre>  Database host:          {dbMeta.host}</pre>
                <pre>  Database name:          {dbMeta.name}</pre>
                <pre>  Database migration:     {dbVersions.migration}</pre>
                <pre>  Database data version:  {dbVersions.data}</pre>
                <pre>  Email to:               {mailTo}</pre>
                Status:
                <br/>
                <pre>  Yahoo game key:         {yahooMeta ? yahooMeta :
                    <Button variant="contained" type="submit" color="primary" style={{height: 18}}
                            onClick={fetchYahooMetadata}>Fetch</Button>}</pre>
                <pre>  Current season year:    {currentSeason.year}</pre>
                <pre>  Current season phase:   {currentLeague.season_phase}</pre>
                <pre>  Current auction:        {currentAuction.season_year} week {currentAuction.week_num}</pre>
                Seasonal Actions:
                <br/>
                <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                        onClick={submitAdvanceToPreDraft}>Advance to {currentSeason.year} pre-draft</Button>
                <br/>
                <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                        onClick={submitAdvanceSeason}>Advance contracts to {currentSeason.year}</Button>
                <br/>
                <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                        onClick={submitAdvanceToDraft}>Advance to {currentSeason.year} draft</Button>
                <br/>
                <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                        onClick={submitFetchNFLScheduleData}>Populate NFL schedule data</Button>
                <br/>
                Weekly Actions:
                <br/>
                <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                        onClick={submitFetchNFLResultsData}>Populate NFL results data</Button>
                Week:
                <Button style={{backgroundColor: "lightgray", height: 25, marginLeft: 10, marginRight: 10}} onClick={handleClickWeek}>{config.weekNums[selectedWeekIndex]}</Button>
                <Menu id="weekPickerMenu" anchorEl={anchorElWeek} keepMounted open={Boolean(anchorElWeek)}
                      onClose={handleClose}>
                    {config.weekNums.map((weekNum, index) => (
                        <MenuItem key={weekNum} value={weekNum} selected={index === selectedWeekIndex}
                                  onClick={(event) => handleWeekMenuItemClick(event, index)}>{weekNum === 0? 'Draft': weekNum}</MenuItem>
                    ))}
                </Menu>
                <br/>
                <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                        onClick={submitYahooSyncPlayers}>Sync player data</Button>
                <br/>
                <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                        onClick={submitYahooSyncTransactions}>Sync transaction data</Button>
                <br/>
                <Button variant="contained" type="submit" color="primary" style={{margin: 15}}
                        onClick={submitMatchTransactions}>Match contracts and transactions</Button>
                <br/>
                Console:
                <br/>
                <pre>{consoleText}</pre>
                <br/>
                <br/>
            </div>
        </Grid>
    );
}