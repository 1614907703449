import React, {useCallback, useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import {BidGroupGrid} from "../components/BidGroupList";
import {BiddingDialog} from "../components/BiddingDialog";
import {NewBidGroupDialog} from "../components/NewBidGroupDialog";
import {WeekPicker} from "../components/WeekPicker";
import {apiGet, apiPost, currentDayOfWeek, getUserJson, getUserName, isUserAdmin} from "../utils";
import Grid from "@material-ui/core/Grid";
import {useMediaQuery} from "@mui/material";

export const AuctionContainer = () => {
    const [auction, setAuction] = useState({});
    const [bidGroups, setBidGroups] = useState([]);
    const [previousBids, setPreviousBids] = useState([]);
    const [openNewBidGroup, setOpenNewBidGroup] = useState(false);
    const [availablePlayers, setAvailablePlayers] = useState([]);
    const [nominatedPlayer, setNominatedPlayer] = useState(null);
    const [openBidding, setOpenBidding] = useState(false);
    const [biddingRow, setBiddingRow] = useState({});
    const [userBankTotals, setUserBankTotals] = useState({});
    const [bidAmount, setBidAmount] = useState(0);
    const [maxBidAmount, setMaxBidAmount] = useState(null);
    const [maxBidAmountBeforeUpdate, setMaxBidAmountBeforeUpdate] = useState(null);
    const [bidError, setBidError] = useState(false);
    const [helperText, setHelperText] = useState('');
    const [maxBidHelperText, setMaxBidHelperText] = useState('');
    const [loading, setLoading] = useState(true);
    const [updatable, setUpdatable] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [updated, setUpdated] = useState(false);

    const isMobile = useMediaQuery('(max-width: 900px)');

    const fetchAuction = () => {
        apiGet(`auction/current`, (data) => {
            setAuction(data.data ? data.data : {});
        });
    };

    const fetchBidGroups = useCallback((season = null, weekNum = null) => {
        setLoading(true);
        const urlDateParam = (season != null && weekNum != null) ? `/season/${season}/week/${weekNum}` : '';
        apiGet(`auction/groups${urlDateParam}`, (data) => {
            setBidGroups(data.data ? data.data : []);
            setLoading(false);
        });
    }, []);

    const fetchBids = (bidGroupId) => {
        apiGet(`auction/bids/${bidGroupId}`, (data) => {
            setPreviousBids(data.data);
        });
    };

    const fetchAvailablePlayers = () => {
        apiGet(`player/available`, (data) => {
            setAvailablePlayers(data.data);
        });
    };

    const fetchUserBankTotals = (bidGroupId, highBid) => {
        apiGet(`contract/bank/${getUserName()}`, (data) => {
            setUserBankTotals(data.data);
            fetchBidMax(bidGroupId, highBid, data.data.available);
        });
    };

    const fetchBidMax = (bidGroupId, highBid, availableBank) => {
        setMaxBidAmount(null);
        setUpdating(true);
        apiGet(`auction/bidmax/manager/${getUserJson().id}/group/${bidGroupId}`, (data) => {
            const bidMaxValue = data.data ? data.data.amount : null;
            setMaxBidAmount(bidMaxValue);
            setMaxBidAmountBeforeUpdate(bidMaxValue);
            determineMaxBidHelperText(bidMaxValue, highBid, availableBank);
            setUpdating(false);
        });
    };

    useEffect(() => {
        fetchAuction();
        fetchBidGroups();
    }, [fetchBidGroups]);

    const handleOpenNewBidGroup = () => {
        fetchAvailablePlayers();
        setMaxBidAmount(null);
        setOpenNewBidGroup(true);
    };

    const handleCloseNewBidGroup = () => {
        setOpenNewBidGroup(false);
    };

    const onPlayerChange = (event, value) => {
        setNominatedPlayer(value);
    };

    const submitBidGroup = () => {
        setLoading(true);
        setOpenNewBidGroup(false);
        if (nominatedPlayer && nominatedPlayer.yahoo_id) {
            const nominationPostBody = {
                playerYahooId: nominatedPlayer.yahoo_id,
                managerName: getUserName()
            }
            apiPost(`auction/nominate`, nominationPostBody, (data) => {
                if (maxBidAmount > 0) {
                    const bidPostBody = {
                        bidGroupKey: data.data.init_bid_group,
                        playerYahooId: nominatedPlayer.yahoo_id,
                        managerName: getUserName(),
                        bidAmount: maxBidAmount
                    }
                    apiPost(`auction/bidmax`, bidPostBody, (data) => {
                        fetchBidGroups();
                    });
                } else {
                    fetchBidGroups();
                }
            });
        }
    };

    const handleOpenBidding = (row) => {
        fetchBids(row['id']);
        fetchUserBankTotals(row['id'], row['high_bid']);
        setBiddingRow(row);
        setUpdatable(false);
        setBidError(false);
        setHelperText('');
        setMaxBidHelperText('');
        setBidAmount(row['high_bid'] + 1);
        setOpenBidding(true);
    };

    const handleCloseBidding = () => {
        setOpenBidding(false);
        setMaxBidAmount(null);
        setMaxBidAmountBeforeUpdate(null);
    };

    const onMaxBidAmountChange = (event) => {
        const value = parseInt(event.target.value);
        setUpdatable(value !== maxBidAmountBeforeUpdate);
        setMaxBidAmount(value);
        determineMaxBidHelperText(value);
    };

    const determineMaxBidHelperText = (value, highBid = biddingRow['high_bid'], availableBank = userBankTotals.available) => {
        let tooLow = false;
        let tooHigh = false;
        if (value && value <= highBid) {
            setMaxBidHelperText('TOO LOW');
            tooLow = true;
        }
        if (value && value > availableBank) {
            setMaxBidHelperText(`${tooLow ? 'TOO LOW and ' : ''}OVER CAP`);
            tooHigh = true;
        }
        if (!tooLow && !tooHigh) {
            setMaxBidHelperText('');
        }
    };

    const onBidAmountChange = (event) => {
        const value = event.target.value;
        setBidAmount(value);
        if (value <= biddingRow['high_bid']) {
            setBidError(true);
            setHelperText('TOO LOW');
        } else {
            setBidError(false);
            setHelperText('');
        }
    };

    const submitMaxBidUpdate = () => {
        setUpdating(true);
        setUpdatable(false);
        const bidPostBody = {
            bidGroupKey: biddingRow.key,
            playerYahooId: biddingRow.yahoo_id,
            managerName: getUserName(),
            bidAmount: maxBidAmount
        }
        apiPost(`auction/bidmax`, bidPostBody, () => {
            setUpdating(false);
            showUpdated();
        });
    };

    const showUpdated = () => {
        setUpdated(true);
        setTimeout(() => {
            setUpdated(false);
        }, 2000);
    };

    const submitBid = () => {
        sendBid(bidAmount);
    };

    const submitImOutBid = () => {
        sendBid(0);
    };

    const sendBid = (amount) => {
        setLoading(true);
        setOpenBidding(false);
        if (bidError && amount !== 0) {
            console.error('Erroneous bidding attempt');
        } else {
            const bidPostBody = {
                bidGroupId: biddingRow.id,
                bidGroupKey: biddingRow.key,
                playerYahooId: biddingRow.yahoo_id,
                managerName: getUserName(),
                bidAmount: amount
            }
            apiPost(`auction/bid`, bidPostBody, () => {
                fetchBidGroups();
            });
        }
    };

    const closeAuction = () => {
        const closePostBody = {
            auctionKey: auction.key
        }
        apiPost(`auction/close`, closePostBody, () => {
            fetchBidGroups();
        });
    };

    const nominationDisabled = () => {
        const dayOfTheWeek = currentDayOfWeek();
        return dayOfTheWeek > 3 || dayOfTheWeek === 0;
    };

    const justify = isMobile ? 'flex-start' : 'center';
    return (
        <Grid container justifyContent={justify}>
            <div style={{textAlign: "right", marginLeft: 10, marginRight: 10}}>
                <WeekPicker fetchFunction={fetchBidGroups} preHandler={() => {}}/>
                {/*<Typography variant="h5" color="inherit" display="inline" style={{marginLeft: 10}}>*/}
                {/*    Nominations for {auction.season_year} week {auction.week_num}*/}
                {/*</Typography>*/}
                {loading && <LinearProgress color="secondary"/>}
                <BidGroupGrid rows={bidGroups} onBiddingButtonClick={handleOpenBidding}/>
                {isUserAdmin() && <Button variant="contained" color="primary" display="inline"
                                          style={{marginTop: 30, marginRight: 30, height: 25}}
                                          onClick={closeAuction}>Close Auction</Button>}
                <Button variant="contained" color="primary" display="inline" style={{marginTop: 30, height: 25}}
                        onClick={handleOpenNewBidGroup} disabled={nominationDisabled()}>Nominate</Button>
                <NewBidGroupDialog open={openNewBidGroup} handleClose={handleCloseNewBidGroup}
                                   playerList={availablePlayers} onPlayerChange={onPlayerChange}
                                   maxBidAmount={maxBidAmount}
                                   onMaxBidAmountChange={onMaxBidAmountChange} onSubmitBidGroup={submitBidGroup}/>
                <BiddingDialog open={openBidding} handleClose={handleCloseBidding} biddingRow={biddingRow}
                               previousBids={previousBids} userBankTotals={userBankTotals}
                               bidAmount={bidAmount} maxBidAmount={maxBidAmount}
                               onBidAmountChange={onBidAmountChange} onMaxBidAmountChange={onMaxBidAmountChange}
                               bidError={bidError} helperText={helperText} maxBidHelperText={maxBidHelperText}
                               onSubmitMaxBidUpdate={submitMaxBidUpdate} onSubmitBid={submitBid}
                               onSubmitImOutBid={submitImOutBid}
                               updatable={updatable} updating={updating} updated={updated}/>
            </div>
        </Grid>
    );
}